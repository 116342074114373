import React from 'react';

const ColoredDots = React.memo((props) => {
  console.log("RENDER ColoredDots");

  return (
    <g id="dot0">
      {props.data && props.data.map((t, index) => (
        <circle
          id={props.dotId(0, t)} // id is important for D3!
          key={props.dotId(0, t)}
          r={t.dotSize} cx={t.x} cy={t.y}
          fill={t.color}
          stroke="#111"
          strokeWidth={0.2}
          />
      ))}
    </g>
  );
});

export default ColoredDots;
