function Accounts(props) {
  if (!props.accounts) return;

  // console.log("RENDER Accounts", props.accounts);

  return (
    <>
      {Object.entries(props.accounts).map(([account, isVisible]) =>
        <div className="menuitem smaller" onClick={() => props.toggleAccount(account)}
          key={account}>
          <img src={isVisible ? 'noun-checkbox-341263-FFFFFF.svg' : 'noun-checkbox-341262-FFFFFF.svg'}
            height="20px" width="20px" />
          <span>{account}</span>
        </div>
      )}
    </>
  );
}

export default Accounts;
