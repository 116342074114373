function Clusters(props) {
  function key(cluster) {
    return "cluster-" + cluster.cluster_level
      + '-' + cluster.cluster_number
      + '-' + props.initializedClusters;
  }

  function clusterClassName(cluster) {
    var result = 'cluster';
    if ((props.hoveredCluster !== null) &&
        (cluster.cluster_number == props.hoveredCluster))
    {
      result += ' hovered';
    }
    return result;
  }

  // console.log("RENDER Clusters");

  return (
    <g>
      {props.clusters && props.clusters.map(cluster => (
        <foreignObject
          x={cluster.x} y={cluster.y}
          width={1} height={1}
          key={key(cluster)}
          >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            className={'clusterLevel'+cluster.cluster_level}
            // onMouseEnter={e => ready() && hoverCluster(cluster.cluster_number)}
            // onMouseLeave={e => ready() && leaveCluster()}
            >
            <div
              className={clusterClassName(cluster)}
              >
              {cluster.caption}
            </div>
          </div>
        </foreignObject>
      ))}
    </g>
  );
}

export default Clusters;
