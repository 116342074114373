import React from 'react';

const TransparentDots = React.memo((props) => {
  console.log("RENDER TransparentDots", props);

  return (
    <g id="dot1">
      {props.data && props.data.map((t, index) => (
        <circle
          id={props.dotId(1, t)} key={props.dotId(1, t)}
          r={t.dotSize} cx={t.x} cy={t.y}
          onClick={e => props.pin(e, t)}
          onMouseEnter={e => !props.isZooming() && props.hover(t)}
          onMouseLeave={e => !props.isZooming() && props.leave(t)}
          fill='transparent'
        />
      ))}
    </g>
  );
});

export default TransparentDots;
