import React, { useState, useEffect } from 'react';
import Accounts from './Accounts.js';


function Menu(props) {
  function hoverComp(_compIndex) {
    props.setCompIndex(_compIndex);
    props.refreshDots(_compIndex);
  }

  function resetComp() {
    console.log("resetComp");
    if (props.compIndex !== props.pinnedCompIndex) {
      hoverComp(props.pinnedCompIndex);
    }
  }

  function pinComp(compIndex) {
    hoverComp(compIndex);
    props.setPinnedCompIndex(compIndex);
  }

  // console.log("RENDER Menu", collections, props);

  return (
    <div id="menu">
      <div className="search">
        <input type="text" name="search" id="search"
          onChange={ e => props.setSearch(e.target.value) }
        />
        <label htmlFor="search">🔎</label>
      </div>
      <ul onMouseLeave={resetComp}>
        <li className={'menuitem' + (props.pinnedCompIndex == -1 ? ' pinned' : '')}
          onMouseEnter={e => hoverComp(-1)}
          onClick={e => pinComp(-1)}
        >
          time
        </li>
        {props.comps && props.comps.map((comp, i) => (
          <li
            key={'' + i + comp.label.toLowerCase()}
            className={'menuitem' + (props.pinnedCompIndex == i ? ' pinned' : '')}
            onMouseEnter={e => hoverComp(i)}
            // onMouseOut={e=> console.log(e)}
            onClick={e => pinComp(i)}
          >
            {comp.label.toLowerCase()}
          </li>
        ))}
      </ul>
      <hr/>
      <div className="menuitem smaller" onClick={props.toggleAging}>
        <img src={props.aging ? 'noun-checkbox-341263-FFFFFF.svg' : 'noun-checkbox-341262-FFFFFF.svg'}
          height="20px" width="20px" />
        <span>fade older tweets</span>
      </div>
      <div>
        <Accounts
          accounts={props.accounts}
          toggleAccount={props.toggleAccount}
        />
      </div>
    </div>
  );
}

export default Menu;
