import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import * as d3 from "d3";
import { pointer } from 'd3-selection';
import { Helmet } from 'react-helmet';

import ColoredDots from './ColoredDots.js';
import TransparentDots from './TransparentDots.js';
import Clusters from './Clusters.js';
import HoveredTweet from './HoveredTweet.js';
import Menu from './Menu.js';
import Collection from './Collection.js';
import { boundsForMap, withMargin } from './utils.js'
import * as db from './db.js'

let loading = new Set();
let initialized = new Set();

function ready() {
  return loading.size == 0;
}

function isZooming() {
  return loading.has('zoom');
}

function load(message, f) {
  if (!loading.has(message)) {
    console.log('LOAD', message, loading);
    loading.add(message);
    if (f) f();
  }
}

function done(message) {
  console.log('DONE', message, loading);
  loading.delete(message);
}

const zoomHandler = d3.zoom();

function saturate(color, k = 1) {
  const {l, c, h} = d3.lch(color);
  return d3.lch(l, c * k, h);
}

function logistic(t) {
  return 1 / (1 + Math.exp(-10*(t-0.5)));
}

function age(c, t) {
  return saturate(c, logistic(t));
}


function Map() {
  const [data,     setData]     = useState(null);
  const [clusters, setClusters] = useState(null);
  const [comps,    setComps]    = useState(null);

  const [collections, setCollections] = React.useState([]);
  const [collection, setCollection] = useState('collection');

  const dataRef     = useRef(null);
  const clustersRef = useRef([]);

  const hitIds = useRef([]);
  const search = useRef('');

  function isSearching() {
    return search.current.length > 1;
  }

  function searchMatches(id) {
    return !isSearching() || hitIds.current.includes(id);
  }

  function setSearch(s) {
    search.current = s.toLowerCase();
    hitIds.current = isSearching()
      ? dataRef.current
          .filter(t => t.full_text.toLowerCase().includes(s))
          .map(t => t.id)
      : [];
    refreshDots();
  }

  const [initializedClusters, setInitializedClusters] = useState(false);

  const [cluster_level, setClusterLevel] = useState(0);
  const [dotSize, setDotSize] = useState(2);
  const [clickedTweet, setClickedTweet] = useState(null);
  const [aging, setAging] = useState(false);

  const hoveredTweetRef    = useRef(null);
  const hoveredTweetBoxRef = useRef(null);
  const tweetBoxRoot       = useRef(null);
  const hoverDotRef        = useRef(null);
  const hoverDotRoot       = useRef(null);

  const [hoveredCluster, setHoveredCluster] = useState(null);

  const transform = useRef(null);
  const [viewBox, setViewBox] = useState([]);
  const [timeRange, setTimeRange] = useState(null);

  const [collectionTrigger, setCollectionTrigger] = useState(-1);

  function bumpCollection() {
    console.log('bumpCollection', (new Date).getTime());
    setCollectionTrigger((new Date).getTime());
  }

  function cachedDots() {
    return window.db2.getCollection('tweets').data;
  }

  function scaleData(_data) {
    // TODO: explain why I need to scale everything up
    const scale = 100;
    _data.forEach(t => {
      t.created_at_datetime = new Date(t.created_at_datetime);
      t.x = scale * t.x;
      t.y = scale * t.y;
    });
    let _b = boundsForMap(_data);
    let timestampRange = _b.maxTime - _b.minTime;
    let _timeRange = [new Date(_b.minTime), new Date(_b.maxTime)];
    setTimeRange(_timeRange);
    _data.forEach(t => {
      t.time01 = (t.created_at_datetime.getTime() - _b.minTime) / timestampRange;
      t.color = d3.interpolatePlasma(t.time01);
      t.agedColor = age(t.color, t.time01);
      let f = Math.sqrt(t.thread_length);
      t.dotSize = f * dotSize;
    });
    let _viewBox = withMargin(0.4,
      [_b.minX, _b.minY, _b.maxX - _b.minX, _b.maxY - _b.minY]);
    setViewBox(_viewBox);
    dataRef.current = _data;
    setData(_data);

    if (_data.length > 0) {
      try {
        let view = window.db2.getCollection('view');
        view.insert({key: 'timeRange', value: _timeRange});
        view.insert({key: 'viewBox', value: _viewBox});
      } catch (e) {
        console.error(e.message);
      }
    }

    window.data = _data;
    done('fetch map');
  }

  useEffect(() => {
    load('fetch map', () => {
      let _data = cachedDots();
      let view = window.db2.getCollection('view');
      if (_data.length == 0) {
        fetch('map.json')
          .then(response => response.text())
          .then(text => JSON.parse(text))
          .then(scaleData);
      } else {
        if (view.data.length == 0) {
          scaleData(_data);
        } else {
          setTimeRange(view.findOne({key: 'timeRange'}).value.map(t => new Date(t)));
          setViewBox(view.findOne({key: 'viewBox'}).value);
        }
        dataRef.current = _data;
        setData(_data);
        window.data = _data;
        done('fetch map');
      }
    });

    load('fetch clusters', () => {
      fetch('clusters.json')
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(_data => {
          console.log('clusters', _data);
          _data.forEach(c => { c.x = 0; c.y = 0; });
          clustersRef.current = _data;
          setClusters(clustersRef.current);
          done('fetch clusters');
        });
    });

    load('fetch comp labels', () => {
      fetch('comp_labels.json')
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(_data => {
          setComps(_data);
          window.comps = _data;
          done('fetch comp labels');
        });
    });
  }, []);

  function init(name, fn) {
    if (!initialized.has(name)) {
      fn();
      initialized.add(name);
    }
  }

  useEffect(() => {
    if (initialized.has('data') &&
        initialized.has('clusters') &&
        initialized.has('comps')) {
      return;
    }
    if (data && !initialized.has('data')) { //} && cachedDots().length == 0) {
      init('data', makeDotsNonOverlapping);
    }
    if (clusters && data && !initialized.has('clusters')) {
      console.log("INIT clusters");
      init('clusters', () => {
        positionClusterLabels();
        setInitializedClusters(true); // hack to make foreignObject update
      })
    }
    if (data && comps && !initialized.has('comps')) {
      console.log("INIT component colors and aged colors");
      init('comps', () => {
        console.log("comps", comps);
        dataRef.current.forEach(tweet => {
          tweet.colors = [];
          tweet.agedColors = [];
          comps.forEach((comp, i) => {
            // normalize component to 0..1
            const c01 = (tweet['' + i] - comp.min) / comp.range;
            const color = d3.interpolatePlasma(1 - c01);
            tweet.colors[i] = color;
            tweet.agedColors[i] = age(color, tweet.time01);
          });
        });
        window.data = dataRef.current; // for debugging
      });
    }
  }, [data, clusters, comps]);


  const dotId = useCallback((n, t) => {
    return 'dot-' + n + '-' + t.id;
  }, []);

  const hover = useCallback((t, force) => {
    if (!force && (t == hoveredTweetRef.current))
      return;

    let tweet = clickedTweet || t;
    hoveredTweetRef.current = tweet;

    let tweetBox = hoveredTweetBoxRef.current;
    if (tweetBox) {
      if (!tweetBoxRoot.current) {
        tweetBoxRoot.current = createRoot(tweetBox);
      }
      tweetBoxRoot.current.render(
        <HoveredTweet
          tweet={tweet}
          compIndex={compIndex}
          aging={aging}
        />
      );
      tweetBox.style.display = 'block';
    }

    moveTweetOverlay(tweet);

    let hoverDot = hoverDotRef.current;
    if (hoverDot) {

      hoverDot.style.display = 'block';
      if (!hoverDotRoot.current) {
        hoverDotRoot.current = createRoot(hoverDot);
      }
      const tweetDot = (
        <circle
          r={tweet.dotSize} cx={tweet.x} cy={tweet.y}
          fill="#fff"
        />
      );
      hoverDotRoot.current.render(tweetDot);
    }
  }, [clickedTweet]);

  const leave = useCallback(() => {
    if (clickedTweet) return;

    hoveredTweetRef.current = null;
    let tweetBox = hoveredTweetBoxRef.current;
    if (tweetBox) {
      tweetBox.style.display = 'none';
    }
  }, [clickedTweet]);

  const zoomRef      = useRef(null);
  const contentRef   = useRef(null);
  const zoomTimerRef = useRef(null);

  const zoomStarted = useCallback(event => {
    load('zoom');
    if (zoomTimerRef.current !== null) {
      clearTimeout(zoomTimerRef.current);
    }
    zoomTimerRef.current = setTimeout(() => {
      done('zoom');
      if (clickedTweet) hover(clickedTweet);
      zoomTimerRef.current = null;
    }, 250);
  }, [clickedTweet, hover]);

  const onZoom = useCallback(event => {
    event.preventDefault();

    let selection = d3.select(zoomRef.current);
    const currentZoom = selection.property("__zoom").k || 1;

    if (event.ctrlKey) {
      // Use mouse wheel + ctrl key, or trackpad pinch to zoom.
      const nextZoom = currentZoom * Math.pow(2, -event.deltaY * 0.01);
      zoomHandler.scaleTo(selection, nextZoom, pointer(event));
    } else {
      // leave();
      zoomHandler.translateBy(
        selection,
        -(event.deltaX / 2 / currentZoom),
        -(event.deltaY / 2 / currentZoom)
      );
    }
    let tweet = clickedTweet || hoveredTweetRef.current;
    if (tweet) moveTweetOverlay(tweet);

    transform.current = selection.property("__zoom");
    contentRef.current.setAttribute("transform", transform.current.toString());
  }, [clickedTweet]);

  useEffect(() => {
    if (!data || !zoomRef.current) {
      console.log("can't initialize zooming yet");
      return;
    }

    console.log("init zooming", zoomRef.current);

    zoomHandler
      .scaleExtent([0.7, 10]) // Limit the zoom scale between 0.7x and 10x
      // .translateExtent([[viewBox[0], viewBox[1]], [viewBox[2], viewBox[3]]])
      .on("start", zoomStarted);

    d3.select(zoomRef.current)
      .call(zoomHandler)
      .on("wheel.zoom", onZoom);
  }, [data]);

  useEffect(() => {
    if (clickedTweet) {
      hover(clickedTweet);
    } else {
      leave();
    }
  }, [clickedTweet]);

  const pin = useCallback((e, t) => {
    e.preventDefault();
    if (clickedTweet && clickedTweet.id == t.id && collection) {
      if (db.toggleInCollection(clickedTweet, collection)) {
        bumpCollection();
      }
    } else {
      setClickedTweet(t);
      moveTweetOverlay(t);
    }
  }, [clickedTweet, collection]);

  const unpin = useCallback((e) => {
    if (!e || !e.defaultPrevented) {
      setClickedTweet(null);
    }
  }, [setClickedTweet]);

  const escHandler = (event) => {
    if (event.key === 'Escape') {
      let tweetBox = hoveredTweetBoxRef.current;
      if (tweetBox && tweetBox.checkVisibility()) {
        unpin();
        leave();
      } else {
        document.getElementById('search').value = '';
        setSearch('');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', escHandler);

    return () => {
      window.removeEventListener('keydown', escHandler);
    };
  }, []);

  function mouseMove(event) {
    let tweet = clickedTweet || hoveredTweetRef.current;
    if (tweet) moveTweetOverlay(tweet);
  }
  
  const [pinnedCompIndex, setPinnedCompIndex] = useState(-1);
  const [compIndex, setCompIndex] = useState(-1);

  // useEffect(() => {
  //   console.log("compIndex", compIndex);
  // }, [compIndex]);

  function toggleAging() {
    setAging(a => !a);
  }

  const [accounts, setAccounts] = useState({'fronx': true, 'fronxer': true});

  useEffect(() => {
    refreshDots();
  }, [aging, accounts]);

  function dotColor(_compIndex, i) {
    let t = dataRef.current[i];
    return accounts[t.account] && searchMatches(t.id)
      ? (_compIndex < 0)
        ? aging
          ? t.agedColor
          : t.color
        : aging
          ? t.agedColors[_compIndex]
          : t.colors[_compIndex]
      : '#3b404c'; // '#4b5262'; //'#3f4552';
  }

  function refreshDots(_compIndex) {
    let cIndex = _compIndex === undefined ? compIndex : _compIndex;
    // console.log("refreshDots", "_compIndex", _compIndex, "compIndex", compIndex, "cIndex", cIndex);
    d3.selectAll('#dot0 circle').style('fill', (d, i) => {
      return dotColor(cIndex, i);
    });
  }

  function toggleAccount(account) {
    let newAccounts = JSON.parse(JSON.stringify(accounts));
    newAccounts[account] = !newAccounts[account];
    setAccounts(newAccounts);
  }

  useEffect(() => {
    if (hoveredTweetRef.current) {
      hover(hoveredTweetRef.current, true);
    }
  }, [compIndex]);

  const timerRef = useRef(null);

  function hoverCluster(cluster_number) {
    if (hoveredCluster != cluster_number) {
      timerRef.current = setTimeout(() => {
        setHoveredCluster(cluster_number);
      }, 100);
    }
  }

  function leaveCluster() {
    if (hoveredCluster != null) {
      console.log("leaveCluster");
      clearTimeout(timerRef.current);
      setHoveredCluster(null);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current); // Clean up on unmount
    };
  }, []);

  function tweet() {
    return hoveredTweetRef.current || clickedTweet;
  }

  var svgPoint = null;

  function moveTweetOverlay(t) {
    let ht = hoveredTweetBoxRef.current;
    if (ht && ht.children[0]) {
      let tweetHeight = ht.children[0].clientHeight;

      if (!svgPoint) svgPoint = zoomRef.current.createSVGPoint();
      svgPoint.x = t.x;
      svgPoint.y = t.y;
      if (transform.current) {
        // Apply the zoom/pan transform to the point
        svgPoint.x = svgPoint.x * transform.current.k + transform.current.x;
        svgPoint.y = svgPoint.y * transform.current.k + transform.current.y;
      }
      var screenCTM = zoomRef.current.getScreenCTM();
      var screenPoint = svgPoint.matrixTransform(screenCTM);

      let top = screenPoint.y - 45;
      let collectionsHeight = 100;
      let marginBottom = 20 + collectionsHeight;
      let marginTop = 10;
      let offset = Math.max(
        -top + marginTop,
        Math.min(
          window.innerHeight - marginBottom - top - tweetHeight,
          0));
      // console.log(tweetHeight, top, offset, top+offset, window.innerHeight - marginBottom - top - tweetHeight);
      
      ht.style.left = 20 + screenPoint.x + 'px';
      ht.style.top = top + offset + 'px';
    }
  }

  useEffect(() => {
    if (dataRef.current && ready()) {
      load('dim');
      let key = 'cluster_level_' + cluster_level;
      dataRef.current.forEach(t => {
        let dot = document.getElementById('dot-0-' + t.id);
        if (dot && (hoveredCluster !== null) && (t[key] != hoveredCluster)) {
          dot.setAttribute('class', 'dimmed');
        } else {
          dot.setAttribute('class', '');
        }
      });
      done('dim');
    }
  }, [cluster_level, hoveredCluster]);

  function makeDotsNonOverlapping() {
    // let tweets = window.db2.getCollection('tweets');
    console.log("makeDotsNonOverlapping", dataRef.current);
    let dots0 = d3.selectAll('#dot0 circle').data(dataRef.current);
    let dots1 = d3.selectAll('#dot1 circle').data(dataRef.current);
    load('non-overlap', () => {
      let tick = 0;
      d3.forceSimulation(dataRef.current)
        .alpha(1)
        .alphaMin(0.01)
        .alphaDecay(0.01)
        .force('collide', d3.forceCollide().radius(t => t.dotSize))
        .on('tick', () => {
          tick += 1;
          let updateFrequency = Math.min(10, Math.ceil(tick / 10));
          // console.log("tick", tick, updateFrequency", updateFrequency);
          if (tick % updateFrequency === 0) {
            dots0
              .attr('cx', d => d.x)
              .attr('cy', d => d.y);
            dots1
              .attr('cx', d => d.x)
              .attr('cy', d => d.y);
          }
        })
        .on('end', () => {
          dots0
            .attr('cx', d => d.x)
            .attr('cy', d => d.y);
          dots1
            .attr('cx', d => d.x)
            .attr('cy', d => d.y);
          done('non-overlap');
        });
    });
  }

  function positionClusterLabels() {
    let clusterXYs = {};
    dataRef.current.forEach(t => {
      let cluster_number = t['cluster_level_0'];
      if (cluster_number == -1) return;
      if (!clusterXYs[cluster_number]) {
          clusterXYs[cluster_number] = { x: 0, y: 0, count: 0 };
      }
      clusterXYs[cluster_number].x += t.x;
      clusterXYs[cluster_number].y += t.y;
      clusterXYs[cluster_number].count++;
    });

    for (let cluster_number in clusterXYs) {
      let cluster = clusterXYs[cluster_number];
      clustersRef.current[cluster_number].x = cluster.x / cluster.count;
      clustersRef.current[cluster_number].y = cluster.y / cluster.count;
    }
    setClusters(clustersRef.current);
    console.log('positionClusterLabels done', clustersRef.current);
  }

  function updateDotXY(id, x, y) {
    _updateDotXY(0, id, x, y);
    _updateDotXY(1, id, x, y);
  }

  function _updateDotXY(n, id, x, y) {
    let dot = document.getElementById('dot-' + n + '-' + id);
    if (dot) {
      if (dot.cx.baseVal.value !== x) dot.cx.baseVal.value = x;
      if (dot.cy.baseVal.value !== y) dot.cy.baseVal.value = y;
    } else {
      // console.log('not found', n, id);
    }
  }

  if (!data) return;

  console.log('RENDER');
  // refreshDots();

  return (
    <div className="rel">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap" rel="stylesheet" />
      </Helmet>
      <svg
        id="svgMap"
        className="map" viewBox={viewBox.join(' ')}
        fontSize={viewBox[2]/60}
        ref={zoomRef}
        onClick={unpin}
        onMouseMove={mouseMove}
        >
        <g ref={contentRef}>
          <ColoredDots
            data={data}
            dotId={dotId}
          />
          <g ref={hoverDotRef} id="hoverDot" style={{'display': 'none'}}>
          </g>
          <Clusters
            clusters={clusters}
            initializedClusters={initializedClusters}
            hoveredCluster={hoveredCluster}
            />
          <TransparentDots
            data={data}
            dotId={dotId}
            isZooming={isZooming}
            hover={hover}
            leave={leave}
            pin={pin}
          />
        </g>
      </svg>
      <Menu
        setSearch={setSearch}
        setCompIndex={setCompIndex}
        pinnedCompIndex={pinnedCompIndex}
        setPinnedCompIndex={setPinnedCompIndex}
        refreshDots={refreshDots}
        comps={comps}
        aging={aging}
        toggleAging={toggleAging}
        accounts={accounts}
        toggleAccount={toggleAccount}
        />
      <div id="subtitle">
        {compIndex > -1 && comps[compIndex].description}
        {(compIndex == -1) && timeRange && (
          <>
            From {timeRange[0].getFullYear()} to {timeRange[1].getFullYear()}
          </>
        )}
      </div>
      <div ref={hoveredTweetBoxRef} id="hoveredTweet" style={{'display': 'none'}}>
      </div>
      <Collection
        collection={collection}
        collections={collections}
        setCollection={setCollection}
        setCollections={setCollections}
        collectionTrigger={collectionTrigger}
        bumpCollection={bumpCollection}
        clickedTweet={clickedTweet}
        hover={hover}
        leave={leave}
        pin={pin}
        />
    </div>
  );
}

export default Map;
