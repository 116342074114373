// https://stackoverflow.com/questions/5796718/html-entity-decode
const decodeEntities = (function() {
  // this prevents any overhead from creating the object each time
  var element = document.createElement('div');

  function decodeHTMLEntities (str) {
    if(str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
})();


function HoveredTweet(props) {
  function lineBroken(text) {
    return text.split('\n').map((item, key) => {
      return <span key={key}>{decodeEntities(item)}<br/></span>
    });
  }

  function color() {
    if (props.compIndex == -1) {
      return props.tweet.color;
    } else {
      return props.tweet.colors[props.compIndex];
    }
  }

  if (!props.tweet) return;
  // console.log("RENDER HoveredTweet");

  return (
    <>
      <div className="wrapperForDeterminingHeight">
        <p className="date">
          <a href={'https://twitter.com/' + props.tweet.account + '/status/' + props.tweet.id}>
            @{props.tweet.account} — {new Date(props.tweet.created_at_datetime).toDateString()}
          </a>
        </p>
        <div className="bar" style={{backgroundColor: color()}}></div>
        <p className="tweet">
          {lineBroken(props.tweet.full_text)}
        </p>
      </div>
    </>
  );
};

export default HoveredTweet;
