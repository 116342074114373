import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import loki from 'lokijs';
import IncrementalIndexedDBAdapter from 'lokijs/src/incremental-indexeddb-adapter';


window.__loki_incremental_idb_debug = true;

function initDb(result) {
  console.log('initDb done', result);

  window.db2.addCollection('tweets', {
    indices: ['id', 'index'],
    unique: ['id'],
  });
  let v = window.db2.addCollection('view', {
    indices: ['key'],
    unique: ['key'],
  });
  window.db2.addCollection('collection-keys', {
    indices: ['key'],
    unique: ['key'],
  });
  window.db2.addCollection('collections', {
    indices: ['key'],
    unique: ['collectionTweetId'],
  });

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

window.db2 = new loki('cybird', {
  adapter: new IncrementalIndexedDBAdapter(),
  autoload: true,
  autoloadCallback: initDb,
  autosave: true,
  autosaveInterval: 1000,
});
