import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { bounds, withMargin } from './utils.js'
import * as d3 from "d3";
import * as db from './db.js'
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

function Collection(props) {

  const [tweets, setTweets] = useState(null);
  const [viewBox, setViewBox] = useState(null);
  const [renaming, setRenaming] = useState(null);

  const f = 1000;
  const height = 50;

  const dbKeys = window.db2.getCollection('collection-keys');
  const dbCollections = window.db2.getCollection('collections');

  function updateCollection() {
    if (!props.collection) return;
    let _tweets = dbCollections.find({key: props.collection});
    _tweets.forEach(item => {
      item.x = item.x || f * item.tweet.time01;
      item.y = item.y || height / 2;
    });
    console.log('tweets', _tweets);
    let _b = bounds(_tweets, item => f * item.tweet.time01);
    console.log('bounds', _b);
    let _viewBox = withMargin(0.1,
        [_b.min, 0, Math.max(100, _b.max - _b.min), height]);
    console.log('viewBox', _viewBox);
    window.tweets = _tweets;
    setViewBox(_viewBox);
    setTweets(_tweets);
  }

  useEffect(() => {
    console.log('useEffect updateCollection', props.collection);
    updateCollection();
  }, [props.collection]);

  useEffect(() => {
    if (tweets) {
      console.log(tweets);
      const node = d3.select('svg.collection').selectAll('circle')
        .data(tweets);

      var simulation = d3.forceSimulation(tweets)
        .force('collide', d3.forceCollide().radius(d => d.tweet.dotSize))
        .nodes(tweets)
        .on('tick', () => {
          node
            .attr('cx', d => d.x)
            .attr('cy', d => d.y);
        });
    }
  }, [tweets]);

  function updateCollections() {
    if (dbKeys) {
      props.setCollections(dbKeys.data.map(col => {
        col.containsClickedTweet = isContained(col.key);
        return col;
      }));
    }
  }

  useEffect(() => {
    updateCollections();
    updateCollection();
  }, [props.collectionTrigger]);

  function collectionTweetId(key, tweet) {
    return key + "-" + tweet.id;
  }

  function addToCollection(collection) {
    if (db.toggleInCollection(props.clickedTweet, collection.key)) {
      props.bumpCollection();
    }
  }

  function deleteCollection(collection) {
    dbKeys.chain().find({key: collection.key}).remove();
    dbCollections.chain().find({key: collection.key}).remove();
    props.bumpCollection();
  }

  function showCollection(collection) {
    props.setCollection(collection.key);
  }

  function newCollection() {
    let name = 'unnamed'
    let x = dbKeys.insert({key: name});
    setRenaming(name);
    props.bumpCollection();
  }

  function renameCollection(previous, value) {
    console.log('renameCollection', previous, value);
    let col = dbKeys.findOne({key: previous});
    col.key = value;
    dbKeys.update(col);
    dbCollections.chain().find({key: previous}).update(item => {
      item.key = value;
      item.collectionTweetId = collectionTweetId(value, item.tweet);
    });
    props.setCollection(value);
    props.bumpCollection();
  }

  function isContained(key) {
    return props.clickedTweet && 
      dbCollections.findOne({
        collectionTweetId: collectionTweetId(key, props.clickedTweet)
      });
  }

  if (!viewBox) return;

  // console.log("RENDER Collection", props);

  return (
    <div className="collections">
      <ul className="tabs">
        {props.collections.map((collection, i) => (
          <li
            key={i}
            className={'tab' + (props.collection == collection.key ? ' pinned' : '')}
            onClick={e => {
              console.log(e);
              if (e.altKey && window.confirm("Delete collection " + collection.key + "?")) {
                // delete
                deleteCollection(collection);
              } else {
                showCollection(collection);
              }
            }}>
            <EditText
              showEditButton
              defaultValue={collection.key}
              onSave={e => { console.log(e); renameCollection(e.previousValue, e.value)}}
              className="editable double important"
              inputClassName="editing"
              style={ {backgroundColor: 'transparent', color: 'white', display: 'inline-block', minHeight: '16px'} }
              editButtonProps={ {
                style: { backgroundColor: 'transparent', width: '12px', minWidth: '12px', height: '12px' },
              } }
              editButtonContent={(
                <img
                  className="editIcon"
                  src='noun-edit-5208207-FFFFFF.svg'
                  height="12px"
                  width="12px"
                />)}
              />
          </li>
        ))}
        <li className="tab" onClick={e => newCollection()}>
          +
        </li>
      </ul>
      <svg className="collection" width="100vw" viewBox={viewBox.join(' ')}>
      {tweets && tweets.map(colTweet => (
        <circle
          id={colTweet.collectionTweetId}
          key={colTweet.collectionTweetId}
          r={colTweet.tweet.dotSize}
          fill={(props.clickedTweet && props.clickedTweet.id == colTweet.tweet.id) ? '#ffffff' : colTweet.tweet.color}
          stroke="#eee"
          strokeWidth={0.5}
          onClick={e => props.pin(e, colTweet.tweet)}
          onMouseEnter={e => props.hover(colTweet.tweet, true)}
          onMouseLeave={e => props.leave(colTweet.tweet)}
          />
      ))}
      </svg>
    </div>
  );
}

export default Collection;
