const dbKeys = () => window.db2.getCollection('collection-keys');
const dbCollections = () => window.db2.getCollection('collections');

function collectionTweetId(collectionKey, tweet) {
  return collectionKey + "-" + tweet.id;
}

function isContained(tweet, collectionKey) {
  return dbCollections().findOne({
    collectionTweetId: collectionTweetId(collectionKey, tweet)
  });
}

export function toggleInCollection(tweet, collectionKey) {
  try {
    if (!dbKeys().findOne({key: collectionKey})) {
      dbKeys().insert({key: collectionKey});
    }
    let id = collectionTweetId(collectionKey, tweet);
    if (isContained(tweet, collectionKey)) {
      if (window.confirm("Remove tweet from collection '" + collectionKey + "'?")) {
        // Remove from collection
        dbCollections()
          .chain()
          .find({collectionTweetId: id})
          .remove();
      }
    } else {
      // Add to collection
      dbCollections().insert({
        key: collectionKey,
        collectionTweetId: collectionTweetId(collectionKey, tweet),
        tweet: tweet,
      });
    }
    return true;
  } catch(e) {
    console.error('clickCollection', collectionKey, tweet, e);
    return false;
  }
}
